<template>
    <li class="nav-item dropdown">
        <a class="nav-link ez-nav-link" data-toggle="dropdown" href="#">
            <el-tooltip class="box-item" effect="dark" content="Belanjaan Anda" placement="bottom">
                <div class="block">
                    <i class="fa fa-shopping-cart text-brown" /> <span class="badge bg-pink text-bold navbar-badge">{{total}}</span>
                </div>
            </el-tooltip>
        </a>
        <div v-if="total" class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div v-if="total">
                <span class="dropdown-item dropdown-header text-success"><i class="fa fa-shopping-bag" /> {{total}} barang</span>
                <div class="dropdown-divider" />
            </div>
            <div v-for="item in carts" :key="item">
                <a href="#" class="dropdown-item">
                    <div class="media">
                        <el-image v-if="item.cover" :src="src + '/' + slugIt(item.category) + '/32x32/' + item.cover" class="product-img mr-3 img-fluid rounded" />
                        <div class="media-body">
                            <h3 class="cart-item-title">{{item.name}}</h3>
                            <small class="text-success"><i class="fa fa-shopping-bag mr-1" /> {{item.qty}} item <span class="float-right">Rp. {{formatIt(item.price)}}/{{item.unit_price}}</span></small>
                        </div>
                    </div>
                </a>
                <div class="dropdown-divider" />
            </div>
            <router-link :to="{name:'cart'}" class="dropdown-item dropdown-footer text-success">
                <i class="fa fa-eye" /> Lihat semua
            </router-link>
        </div>
        
        <ul v-if="!total" class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px">
            <span class="dropdown-item dropdown-header text-orange text-bold">Keranjang Kamu kosong</span>
            <div class="dropdown-divider" />
            <li class="user-header">
                <img src="/img/emptycart.jpg" class="img-fluid" alt="segarpedia user image" />
            </li>
        </ul>
    </li>
</template>

<script>
import { $axios, baseUrl, cdn} from './../../helpers/axios.conf';
import accounting from './../../assets/js/accounting.min.js'
import voca from './../../assets/js/voca.min.js'

export default {
    name: 'cart',
    mounted() {
        this.fetchCart()
    },
    data() {
        return {
            src: cdn,
            total: null,
            carts: null
        }
    },
    methods: {
        async fetchCart(){
            try {
                const res = await $axios.get(baseUrl + '/product/mycart')
                if(res.data.status) {
                    this.carts = res.data.cart
                    this.total = res.data.total
                    return
                }
            } catch(e) {}
        },
        formatIt(a){
            return accounting.formatNumber(a)
        },
        slugIt(a){
            return voca.slugify(a)
        },
    }
}
</script>
