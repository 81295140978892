import { $axios, baseUrl} from './axios.conf';
import { ElNotification, ElMessageBox} from 'element-plus'

export function notify(type, title, message, duration) {
    ElNotification({
        type: type,
        title: title,
        message: message,
        duration: duration
    })
}

export function messageBox(message, okAction) {
    ElMessageBox.confirm(
        message,
        'Konfirmasi',
        {
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
            type: 'info',
        })
        .then(() => {
            okAction
        })
        .catch(() => {})
}

export function seopage(metakey, metadesc) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', metakey)
    document.querySelector('meta[name="description"]').setAttribute('content', metadesc)
}

export function myWish(pid) {
    return $axios.get(baseUrl + '/product/wish/' + pid)
}
export function beMine(p){
    return $axios.post(baseUrl + '/product/bemine', p)
}

export function shareIt(message){
    ElMessageBox.confirm(
        message,
        'Bagikan perhatian Mu',
        {
            confirmButtonText: 'Copy',
            cancelButtonText: 'Tidak',
            type: 'info',
        })
        .then(() => {
            console.log('testing copy')
        })
        .catch(() => {})
}

export function countDown(timeFrom) {
    return
}