import { createRouter, createWebHistory } from 'vue-router'
import account from './account'
import product from './product'

const baseRoute = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: process.env.VUE_APP_HOMEPAGE_TITLE
        },
        component: () => import('../views/Index.vue')
    }, {
        path: '/cart',
        name: 'cart',
        meta: {
            title: "Keranjang belanja",
            requiresAuth: true
        },
        component: () => import('../views/product/cart.vue')
    }, {
        path: '/wishlist',
        name: 'wishlist',
        meta: {
            title: "Wishlist",
            requiresAuth: true
        },
        component: () => import('../views/product/wishlist.vue')
    }, {
        path: '/transactions',
        name: 'trx',
        meta: {
            title: "Daftar transaksi",
            requiresAuth: true
        },
        component: () => import('../views/order/index.vue')
    }, {
        path: '/transaction/:t',
        name: 'DetailTransactions',
        meta: {
            title: "Daftar transaksi",
            requiresAuth: true
        },
        component: () => import('../views/order/detail.vue')
    },  {
        path: '/order/complaint/:trx',
        name: 'complaint',
        meta: {
            title: "Complaint",
            requiresAuth: true
        },
        component: () => import('../views/order/complaint.vue')
    }, {
        path: '/settings',
        name: 'settings',
        meta: {
            title: "Account Setting",
            requiresAuth: true
        },
        component: () => import('../views/settings/index.vue')
    }, {
        path:'/:category/:slug',
        name: 'topage',
        meta: {
            title: ''
        },
        component: () => import('../views/product/page.vue'),
    }, {
        path: '/:catslug',
        name: 'ProductCategory',
        meta: {
            title: ''
        },
        component: () => import('../views/product/category.vue'),
    }, {
        path: '/search',
        name: 'Search',
        meta: {
            title: ''
        },
        component: () => import('../views/product/index.vue'),
    }, {
        path: '/faq',
        name: 'faq',
        meta: {
            title: ''
        },
        component: () => import('../views/page/faq.vue'),
    },{ 
        path: '/404', 
        name: 'NotFound', 
        meta: {
            title: 'Halaman tidak ditemukan'
        },
        component: () => import('../views/404.vue'),
    }, {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
        redirect: '/404',
    }, 
]

const routes = baseRoute.concat(
    account, product,
)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
