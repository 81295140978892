<template>
    <el-button @click="diaLogVisible = true" class="text-success" size="small" text>
        <i class="fa fa-user-shield float-left mr-1"></i> <span class="float-left d-none d-md-block">Masuk</span>
    </el-button>
    <el-dialog v-model="diaLogVisible" title="Login" :width="dialogWidth" :show-close="false">
        <el-form ref="credentials" :model="credentials" :rules="rules" label-width="0px">
			<el-form-item prop="email" style="margin-bottom:25px !important;">
				<el-input
					v-model="credentials.email"
					placeholder="valid email"
					clearable
				/>
			</el-form-item>
			<el-form-item prop="password" style="margin-bottom:25px !important;">
				<el-input v-model="credentials.password" placeholder="password" show-password clearable />
			</el-form-item>
            <div class="text-right">
                <el-button-group>
                    <el-button type="success" @click="diaLogVisible = false" plain round>
                        <i class="fa fa-ban" /> Cancel
                    </el-button>
                    <el-button type="success" @click="doLogin('credentials')" round>
                        <i class="fa fa-fingerprint" /> Login
                    </el-button>
                </el-button-group>
            </div>
			<div class="text-right">
                <a href="javascript:void(0)" @click="forgotPassword">
					<small class="text-danger">Lupa Password</small>
				</a>
            </div>
		</el-form>
    </el-dialog>

    <el-dialog v-model="dialogForgot" title="Permintaan reset password" :width="dialogWidth" :show-close="false">
        <small>Kami akan mengirimkan link permintaan reset password kealamat email yang anda kirim</small> 
        <el-form
			ref="forgot"
			:model="forgot"
			:rules="ruleforgot"
			label-width="0px"
			>
			<el-form-item prop="email" style="margin-bottom:25px !important;">
				<el-input
					v-model="forgot.email"
					placeholder="valid email address"
					clearable
				/>
			</el-form-item>
			
			<button type="button" class="btn btn-success btn-block" @click="reqReset('forgot')">
				<i class="fa fa-paper-plane" /> Kirim
			</button>
            <el-row :gutter="0">
				<el-col :span="12">
					<a href="javascript:void(0)" @click="go2login">
						<small class="text-danger"><i class="fa fa-user-shield"></i> Login</small>
					</a>
				</el-col>
			</el-row>
		</el-form>
    </el-dialog>
</template>

<script>
import { $axios, baseUrl} from './../../helpers/axios.conf';
import { notify, messageBox } from './../../helpers/func'
import qs from 'qs';

export default {
    data() {
        return {
            diaLogVisible: false,
            dialogForgot: false,
            dialogWidth: '20%',
            ww: window.innerWidth,
            credentials: {
				email: null,
				password: null,
			},
			auth: {
				status: false,
				token: null,
			},
			rules: {
				email: [
					{
						required: true,
						message: "Please input with valid email",
						trigger: "blur",
					},
					{
						min: 5,
						message: "Length should be 5 to 30",
						trigger: "blur",
					},
					{
						type: 'email',
						message: 'Please input correct email address',
						trigger: ['blur', 'change'],
					},
				],
				password: [
					{
						required: true,
						message: "Please input your password",
						trigger: "blur",
					},
					{
						min: 8,
						message: "Length should be 8 to 50",
						trigger: "blur",
					},
				],
			},
            forgot: {
                email: null
            },
            ruleforgot: {
                email: [
					{
						required: true,
						message: "email required",
						trigger: "blur",
					},
					{
						type: 'email',
						message: 'Please input correct email address',
						trigger: ['blur', 'change'],
					},
				],
            }
        }
    },
    mounted(){
        this.setDialogWidth()
        window.addEventListener('resize', this.onWResize)
    },
    watch: {
        ww: function () {
			this.setDialogWidth()
		},
		'credentials.email': function(v){
			if(!v)return
			v = v.replace(' ', '')
			this.credentials.email = v.toLowerCase()
		}
    },
    methods: {
        onWResize() {
			this.ww = window.innerWidth
		},
        setDialogWidth(){
            if(this.ww <= 375) {
                this.dialogWidth = '90%'
            }
            if((this.ww > 375)&&(this.ww <= 480)) {
                this.dialogWidth = '65%'
            }
            if((this.ww > 480)&&(this.ww <= 1024)) {
                this.dialogWidth = '35%'
            }
            if(this.ww > 1024) {
                this.dialogWidth = '20%'
            }
        },
        doLogin(f) {
			this.$refs[f].validate((v) => {
				if (v) {
					this.login(f)
				} else {
					this.$message({
						type: "error",
						message: "Please input your form registration correctly and with your valid data",
					})
					return
				}
			});
		},
        async login(a) {
			const loader = this.$loading({
				lock: true,
				text: "Login process please wait...",
				spinner: "fas fa-infinity text-maroon fa-spin fa-2x",
				customClass: "ez-loader-background",
			});
			try {
                const res = await $axios.post(baseUrl + '/auth/login', qs.stringify(this.credentials))
				loader.close();
				if (!res.data.status) {
                    this.$store.dispatch('auth/failed');
                    this.$message({
                        type: 'error',
                        message: res.data.message
                    })
					return
                }
				this.diaLogVisible = false
                this.$store.dispatch('auth/success', res.data.auth);
                this.$store.dispatch('user/assign', res.data.user);
                // prevent bad css rendering
                window.location.reload();
			} catch (e) {
                loader.close()
				if (e.response) {
					this.$store.dispatch('auth/failed')
					this.$message({
						type: 'error',
						message: e.response.data.message
					})
				}
				//window.location.reload()
            }
		},
        forgotPassword(){
            this.diaLogVisible = false
            this.dialogForgot = true
        },
        go2login(){
            this.diaLogVisible = true
            this.dialogForgot = false
        },
        async reqReset(a){
            const loader = this.$loading({
				lock: true,
				text: "Login process please wait...",
				spinner: "fa fa-radiation-alt fa-spin fa-2x",
				customClass: "ez-loader-background",
			});
            try {
                const res = await $axios.post(baseUrl + '/auth/request-reset-password', qs.stringify(this.forgot))
				if (res.data.status) {
                    loader.close()
                    // clear form
                    this.$refs[a].resetFields()
                    notify('success', 'Success', res.data.message, 15000)
                    
                } else {
                    loader.close();
                    notify('warning', 'Success', res.data.message, 15000)
                }
                this.dialogForgot = false
				return
			} catch (e) {
                loader.close()
				if (e.response) {
					this.$message({
						type: 'error',
						message: e.response.data.message
					})
				}
				//window.location.reload()
            }
        },
    }
}
</script>
