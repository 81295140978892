<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
export default {
    watch: {
        '$route' (to, form) {
            document.title = (to.meta.title || 'Situs Belanja Makanan Segar, Berkualitas & Termurah') + ' | ' + process.env.VUE_APP_NAME 
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || 'frontend') + '-layout';
        }
    }
}
</script>
