<template>
    <li class="nav-item dropdown">
        <a class="nav-link ez-nav-link" data-toggle="dropdown" href="#">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="Notifikasi"
                placement="bottom"
            >
            <div class="block"><i class="far fa-bell text-brown" /> <span class="badge bg-aqua navbar-badge text-bold">15</span></div>
            </el-tooltip>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider" />
        <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2" /> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider" />
        <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2" /> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider" />
        <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2" /> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider" />
        <a href="#" class="dropdown-item dropdown-footer"
            >See All Notifications</a
        >
        </div>
    </li>
</template>
