<template>
	<footer class="main-footer">
		<div class="container">
			<div class="row">
				<div class="col-md-4 col-6">
					<div class="h6 pb-0 mb-0 text-success">Segarpedia</div>
					<small class="p-0">Beli yang segar dan termurah secara online</small>
					<p class="mt-3">
						Temukan apapun kebutuhan mu dengan harga terbaik cuma di Segarpedia. 
						Segarpedia adalah pusat perbelanjaan sayur, buah, daging dan ikan segar, kebutuhan dapur dan makanan - makanan dasar lainnya secara online. 
					</p>
				</div>
				<div class="col-md-2 col-6">
					<div class="h6 mb-3"><i class="fa fa-handshake text-green" /> Mitra</div>
					<ul class="ul-segar">
						<li>UKM</li>
						<li>Rekanan</li>
					</ul>
				</div>
				<div class="col-md-2 col-6">
					<div class="h6 mb-3"><i class="fa fa-map-signs text-orange" /> Panduan</div>
					<ul class="ul-segar">
						<li>
							<router-link :to="{name: 'faq'}"><i class="fa fa-comments" /> FAQ</router-link>
						</li>
						<li>
							<router-link :to="{name: 'trx'}">Complain</router-link>
						</li>
						<li>Syarat dan Ketentuan</li>
						<li>Kebijakan Privasi</li>
					</ul>
				</div>
				<div class="col-md-2 col-6">
					<div class="h6 mb-3"><i class="fa fa-heartbeat text-pink" /> Follow Us</div>
					<ul class="ul-segar">
						<li><a href="https://facebook.com/segarpedia" target="_blank"><i class="fab fa-facebook-square text-primary" /> Facebook</a></li>
						<li><i class="fab fa-twitter-square text-info" /> Twitter</li>
						<li><i class="fab fa-instagram-square tg-purple" /> Instagram</li>
						<li><i class="fab fa-youtube-square text-danger" /> Youtube</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			copy: String,
			app_version: String,
			app_url: String,
		};
	},
	mounted() {
		this.copy = process.env.VUE_APP_COPY;
		this.app_version = process.env.VUE_APP_VERSION;
	},
};
</script>

<style scoped>
.ul-segar {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}
</style>