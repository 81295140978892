<template>
    <el-affix :offset="0" position="bottom" class="d-block d-sm-none" style="z-index:500 !important">
        <div class="box elevation-3 tb">
            <div class="row">
                <div class="col text-center">
                    <router-link :to="{name:'home'}">
                        <div class="block tb-menu"><i class="fa fa-store fa-oz tg-blue" /></div>
                        <span class="oz-label-toolbar" :class="routeName=='home' ? 'act' : ''">Home</span>
                    </router-link>
                </div>
                <div class="col text-center">
                    <router-link :to="{name:'Search'}">
                        <div class="block tb-menu"><i class="fa fa-spa fa-oz tg-green" /></div>
                        <span class="oz-label-toolbar" :class="routeName=='Search' ? 'act' : ''">Products</span>
                    </router-link>
                </div>
                <div class="col text-center">
                    <router-link :to="{name:'wishlist'}">
                        <div class="block"><i class="fa fa-heartbeat fa-oz tg-pink" /></div>
                        <span class="oz-label-toolbar" :class="routeName=='wishlist' ? 'act' : ''">Wishlist</span>
                    </router-link>
                </div>
                <div class="col text-center">
                    <router-link :to="{name:'cart'}">
                        <div class="block tb-menu"><i class="fa fa-shopping-cart fa-oz tg-yellow" /></div>
                        <span class="oz-label-toolbar" :class="routeName=='cart' ? 'act' : ''">Orders</span>
                    </router-link>
                </div>
                <div class="col text-center">
                    <router-link :to="{name:'trx'}">
                        <div class="block tb-menu"><i class="fa fa-file-invoice-dollar fa-oz tg-purple" /></div>
                        <span class="oz-label-toolbar" :class="routeName=='trx' ? 'act' : ''">Transactions</span>
                    </router-link>
                </div>
            </div>
        </div>
    </el-affix>
</template>

<script>
export default {
    data(){
        return {
            routeName: null
        }
    },
    mounted(){
        this.routeName = this.$route.name
    },
    watch: {
        $route (to, from){
            this.routeName = this.$route.name
        },
    }
}
</script>

<style scoped>
    .tb {
        border-top: 1px solid rgb(38, 196, 6);
    }
    .act {
        color:rgb(0, 137, 18);
        font-weight: 500;
        text-decoration: underline;
        text-decoration-color: rgb(133, 222, 0);
        text-decoration-thickness: 4px;
        text-decoration-style:dotted;
    }
</style>