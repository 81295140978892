<template>
	<nav class="main-header navbar navbar-expand fixed-top navbar-white navbar-light">
		<div class="container-fluid">
			<el-tooltip class="box-item" effect="dark" content="Beli yang segar, sehat dan termurah secara online hanya di Segarpedia" placement="bottom">
				<a href="/" class="navbar-brand d-none d-sm-block">
					<img src="/brand.png" alt="segarpedia" class="brand-image" height="50" />
				</a>
			</el-tooltip>
			<search />
			<ul class="navbar-nav ml-auto">
				<cart v-if="authUser" ref="daCart" />
				<notifications v-if="shownotif" />
				<account />
			</ul>
		</div>
	</nav>
</template>

<script>
import notifications from './navbar/Notif'
import cart from './navbar/Cart'
import search from './Search.vue'
import account from './navbar/Account.vue'

export default {
	name: 'Navbar',
	components:{
		cart,
		notifications,
		account,
		search
	},
	data() {
		return {
			authUser: null,
			shownotif: false
		}
	},
	mounted() {
		if (this.$store.state.auth.isAuth) {
			this.authUser = true
		} else {
			this.authUser = false
		}
	},
	watch: {
		//
	},
	methods: {
		//
	},
}
</script>

<style scoped>
.el-form-item {
	margin-bottom: 0px;
}
.location-block {
	width: 300px;
}
.oji-circle {
	margin-left: 10px;
}
.fa-oji {
	font-size: 19pt;
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
}
.fa-oji:hover{
	color:rgb(82, 204, 71) !important;
}
</style>
