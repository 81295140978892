<template>
    <div v-if="!authUser" class="account">
        <register />
        <login />
    </div>
    <authuser v-if="authUser" />
</template>
<script>
import Register from './Register.vue'
import Login from './Login.vue'
import AuthUser from './AuthUser.vue'

export default {
    components: {
        register: Register,
        login: Login,
        authuser: AuthUser
    },
    data() {
		return {
			authUser: null
		}
	},
    mounted() {
		if (this.$store.state.auth.isAuth) {
			this.authUser = true
		} else {
			this.authUser = false
		}
	},
}
</script>

<style scoped>
.account {
    width: 190px;
    padding: 0px;
}
.auser {
    height: 40px;
}
@media screen and (max-width: 375px) {
    .account {
        width: 105px;
        padding: 0px;
    }
}
@media screen and (min-width: 375px) and (max-width: 680px) {
    .account {
        width: 105px;
        padding: 0px;
    }
}
</style>