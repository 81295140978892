const routeAccount = [
    {
        path: '/auth',
        name: 'Auth',
        meta: {
            title: 'Authentication'
        },
        component: () => import('../views/auth/Index.vue')
    }, {
        path: '/verification/:token',
        name: 'Verification',
        meta: {
            title: 'Verifikasi akun'
        },
        component: () => import('../views/auth/Verification.vue')
    }, {
        path: '/account',
        name: 'MyAccount',
        meta: {
            title: "My Account",
            requiresAuth: true
        },
        component: () => import('../views/account/index.vue')
    }, {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('../views/auth/ResetPassword.vue')
    }
]

export default routeAccount