<template>
    <el-button @click="showRegister" class="btnreg" size="small" text>
        <i class="fa fa-address-card float-left mr-1"></i> <span class="float-left d-none d-md-block">Daftar</span>
    </el-button>

    <el-dialog v-model="dialogVisible" title="Registrasi" :width="dialogWidth" :show-close="false">
        <el-form ref="reg" :model="reg" :rules="rules" label-position="top">
            <el-form-item label="Username" prop="username">
                <el-input v-model="reg.username" autocomplete="off" clearable />
            </el-form-item>
            <el-form-item label="Email" prop="email" class="mt-2">
                <el-input v-model="reg.email" autocomplete="off" clearable/>
            </el-form-item>
            <el-form-item label="Password" prop="password" class="mt-2">
                <el-input v-model="reg.password" type="password" autocomplete="off" show-password clearable/>
            </el-form-item>
            <el-form-item label="Confirm Password" prop="confirm_password" class="mt-2">
                <el-input v-model="reg.confirm_password" type="password" autocomplete="off" show-password clearable />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button-group>
                <el-button type="success" @click="this.$refs['reg'].resetFields()" plain><i class="fa fa-erase mr-2" /> Clear</el-button>
                <el-button type="success" @click="onSubmit('reg')">
                    <i class="fa fa-check-double mr-2" /> Daftar
                </el-button>
            </el-button-group>
        </template>
    </el-dialog>

    <el-dialog v-model="dialogConfirm" title="Konfirmasi" :width="dialogWidth">
        <span>Kami akan mengirimkan kode verifikasi ke alamat <b style="color:#cc0177">{{reg.email}}</b></span>
        <template #footer>
        <span class="dialog-footer">
            <el-button-group>
                <el-button type="success" plain @click="dialogConfirm = false">Batal</el-button>
                <el-button type="success" @click="doSubmit('reg')">Ya. Kirim</el-button>
            </el-button-group>
        </span>
        </template>
    </el-dialog>
</template>
<script>
import { $axios, baseUrl} from './../../helpers/axios.conf';
import qs from 'qs';
import {notify} from './../../helpers/func'

export default {
    data(){
        var confirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password again'));
            } else if (value !== this.reg.password) {
                callback(new Error('Password don\'t match!'));
            } else {
                callback();
            }
        };
        return {
            dialogConfirm: false,
            dialogVisible: false,
            dialogWidth: '20%',
            ww: window.innerWidth,
            reg: {
                username: null,
                email: null,
                password: null,
                confirm_password: null
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: 'username required',
                        trigger: 'blur'
                    }, {
                        min: 5,
                        max: 35,
                        message: 'minimum character is 8 and maximal 35',
                        trigger: 'blur'
                    },
                ],
				email: [
					{
						required: true,
						message: "email required",
						trigger: "blur",
					},
					{
						type: 'email',
						message: 'Please input correct email address',
						trigger: ['blur', 'change'],
					},
				],
				password: [
					{
						required: true,
						message: "Please input your password",
						trigger: "blur",
					},
					{
						min: 8,
						message: "Length should be 8 character",
						trigger: "blur",
					},
				],
                confirm_password: [
                    {
						required: true,
						message: "Please input your password",
						trigger: "blur",
					},
                    {
                        validator: confirmPassword, 
                        trigger: 'blur',
                    }
                ]
			},
        }
    },
    mounted(){
        this.setDialogWidth()
        window.addEventListener('resize', this.onWResize)
    },
    watch: {
        ww: function () {
			this.setDialogWidth()
		},
        'reg.email': function(v){
            if(!v)return
            v = v.replace(' ', '')
            this.reg.email = v.toLowerCase()
        }
    },
    methods: {
        onWResize() {
			this.ww = window.innerWidth
		},
        setDialogWidth(){
            if(this.ww <= 375) {
                this.dialogWidth = '90%'
            }
            if((this.ww > 375)&&(this.ww <= 480)) {
                this.dialogWidth = '65%'
            }
            if((this.ww > 480)&&(this.ww <= 1024)) {
                this.dialogWidth = '35%'
            }
            if(this.ww > 1024) {
                this.dialogWidth = '20%'
            }
        },
        showRegister(){
            this.dialogVisible = true
        },
        onSubmit(f) {
			this.$refs[f].validate((v) => {
				if (v) {
					this.dialogConfirm = true
                    return
				} else {
					this.$message({
						type: "error",
						message: "Please input your form correctly and with your valid data",
					})
                    return
				}
			});
		},
        async doSubmit(a){
            const loader = this.$loading({
				lock: true,
				text: "Registration process please wait...",
				spinner: "fas fa-infinity fa-spin fa-2x",
				customClass: "ez-loader-background",
			});

            try {
                var res = await $axios.post(baseUrl + '/auth/register', qs.stringify(this.reg))
                if (!res.data.status) {
                    loader.close()
                    notify('error', 'Failed', res.data.message, 5000)
                    return
                }
                loader.close()
                
                // clear form register
                this.$refs[a].resetFields()

                // hide register dialog
                this.dialogVisible = false
                notify('success', 'Success', res.data.message, 5000)
                if(res.data.token) {
                    setInterval(()=>{
                        window.location = '/verification/' + res.data.token
                    }, 5000)
                }
                if(res.data.noverify) {
                    setInterval(()=>{
                        window.location = '/auth'
                    }, 5000)
                }
                return
            }catch(e) {
                loader.close()
                notify('error', 'Failed', e.message, 5000)
            }
        }
    }
}
</script>

<style scoped>
.btnreg {
    color: rgb(75, 187, 47);
}
</style>