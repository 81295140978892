<template>
    <li class="nav-item dropdown user-menu ml-1">
        <a href="#" class="nav-link dropdown-toggle ez-usr" data-toggle="dropdown" aria-expanded="false">
            <img :src="srcNav" alt="segarpedia" class="auser img-circle mt-1">
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px">
            <li class="user-header">
                <img :src="srcDrop" class="img-circle elevation-2" alt="segarpedia user image" />
                <p>{{user.name}}</p>
            </li>
            <li class="card-body">
                <div class="row text-sm">
                    <div class="col-4 text-center">
                        <router-link to="/wishlist">
                            <i class="fa fa-heart text-pink fa-2x" /><br />Wishlist
                        </router-link>
                    </div>
                    <div class="col-4 text-center">
                        <router-link :to="{name:'trx'}">
                            <i class="fa fa-shopping-bag text-teal fa-2x" /> Transaksi
                        </router-link>
                    </div>
                    <div class="col-4 text-center">
                        <router-link :to="{name: 'MyAccount'}">
                            <i class="fa fa-tools text-orange fa-2x" /> Pengaturan
                        </router-link>
                    </div>
                </div>
            </li>
            <li class="user-footer">
                <a class="btn btn-default btn-flat float-right" @click="exit">
                    <i class="fa fa-power-off text-danger" /> Keluar
                </a>
            </li>
        </ul>
    </li>
</template>

<script>

import { logout } from './../../helpers/auth'

export default {
    mounted(){
        this.srcNav = '/img/avatar_30.jpg'
        this.srcDrop = '/img/avatar_73.jpg'
    },
    data() {
        return {
            cdn: process.env.VUE_APP_CDN_HOST,
            srcNav: null,
            srcDrop: null,
            user: {
                name: this.$store.state.user.username,
                avatar: this.$store.state.user.avatar
            }
        }
    },
    methods: {
        async exit() {
            const loader = this.$loading({
                lock: true,
                text: "please wait...",
                spinner: "fas fa-radiation fa-spin fa-2x",
                customClass: "ez-loader-background",
            });
            try {
				// ignore error from api (if any)
				await logout()
                this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
				loader.close();
            } catch (e) {
                this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
				loader.close();
            }
            window.location.reload();
		},

    }
}
</script>


<style scoped>
.ez-usr {
    padding: 0px !important;
    margin: 0px !important;
}
</style>