require('./bootstrap');
require('./assets/js/bootstrap.bundle.min.js');

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Frontend from './layouts/Frontend.vue'
import Plain from './layouts/Plain.vue'

import "./assets/css/app.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fall.min.css";
import "./assets/css/adminlte.css";
import "./assets/css/ez.css";

const app = createApp(App)

app.component('frontend-layout', Frontend)
app.component('plain-layout', Plain)

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isAuth = store.state.auth.isAuth
    
    if (requiresAuth && !isAuth) {
        next({
            path: '/auth'
        })
    } else if (to.path == '/auth' && isAuth) {
        next('/')
    } else {
        next()
    }
})

app.use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')
