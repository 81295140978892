<template>
    <el-input v-model="search" placeholder="cari yang segar - segar disini" class="input-with-select mr-2" clearable @keyup.enter.native="findIt">
      <template #append>
            <el-button @click="findIt">
                <i class="fa fa-search text-success" />
            </el-button>
      </template>
    </el-input>
</template>

<script>
export default {
    data(){
        return {
            search: null,
            select: null
        }
    },
    methods: {
        findIt() {
            this.$router.push({name: 'Search', query:{item:this.search}})
        }
    }
}
</script>

<style>
.input-with-select .el-input-group__prepend {
  background-color: var(--el-fill-color-blank);
}
</style>