<template>
	<div class="wrapper">
		<ez-nav />
		<div class="content-wrapper">
			<section class="content">
				<div class="container">
					<slot />
				</div>
			</section>
		</div>
		<ez-toolbar />
		<ez-footer v-if="show" />
		<div id="ez-overlay" style="visibility: hidden" />
	</div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import ToolBar from "../components/ToolBar.vue";
import { refreshToken } from "../helpers/auth";

export default {
    name: "Dashboard",
	components: {
		"ez-nav": Navbar,
		"ez-footer": Footer,
		"ez-toolbar": ToolBar
	},
	created(){
		window.addEventListener('resize', this.onWResize)
		this.setWidth()
	},
	mounted(){
		//window.addEventListener('resize', this.onWResize())
		// 25 minutes request refresh token 1500000
		// if (this.$store.state.auth.isAuth) {
		// 	setInterval(function () {
		// 		this.freshToken()
		// 	}.bind(this), 1500000)
		// }
	},
	data(){
		return {
			show: true,
			ww: window.innerWidth
		}
	},
	watch: {
        ww: function () {
			this.setWidth()
		},
    },
	methods: {
		onWResize() {
			this.ww = window.innerWidth
		},
		setWidth(){
            if(this.ww >= 600) {
                this.show = true
            }else{
				this.show = false
			}
        },
		async freshToken() {
            try {
				const r = await refreshToken()
				if (r.data.status) {
                    this.$store.dispatch('auth/refreshAuth', r.data.auth)
                } else {
                    this.$store.dispatch('auth/logout')
                    this.$store.dispatch('user/revoke')
                    this.$router.push({
                        name: 'Login'
					})
					window.location.reload()
                }
			} catch(e) {
				this.$store.dispatch('auth/logout')
                this.$store.dispatch('user/revoke')
				window.location.href = '/'
			}
        },
	},
};
</script>
