const product = [
    {
        path: '/i/:product_slug',
        name: 'detail_product',
        meta: {
            title: "detail product",
        },
        component: () => import('../views/product/detail.vue')
    }
]

export default product